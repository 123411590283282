import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import Soucek from "../../../images/reference/kiwi/soucek.jpg"
import Pezlarova from "../../../images/reference/kiwi/pezlarova.jpg"
import Logo from "../../../images/reference/kiwi/logo.png"
import Emailing from "../../../images/reference/kiwi/emailing-cs.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import Plane from "../../../images/reference/kiwi/brand-image_2x.jpg"
import Medailon from "@igloonet-web/shared-ui/components/reference/medailon"
import Runway from "../../../images/reference/kiwi/runway.jpg"
import Mountains from "../../../images/reference/kiwi/background_mountain.jpg"
import Wing from "../../../images/reference/kiwi/wing.jpg"
import City from "../../../images/reference/kiwi/city_flight.jpg"
import OldPlane from "../../../images/reference/kiwi/old_plane.jpg"
import LongReferenceImage from "../../../components/reference-detail/long-reference-image"

const Kiwi: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Reference na marketingové práce pro Kiwi.com | igloonet</title>
        <meta
          name="description"
          content="Spolupráci jsme začali konzultacemi k propojování marketingu s daty.
            Dvakrát měsíčně jsme konzultovali marketingové analýzy - pomáhali s pochopením
            souvislostí, které analýzy mají, jak k nim přistoupit a jaká doporučení na jejich
            základě udělat. Postupně jsme spolupráci rozšířili o výkonnostní kampaně na sociálních
            sítích a e-mailing."
        />
      </Helmet>

      <ReferenceHeader
        companyName="Kiwi.com"
        heading="Mentoring interního týmu</br>-&nbsp;data a marketing"
        date="Září 2018 - květen 2020"
      />
      <ReferenceImageText
        right
        img={Plane}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo společnosti Kiwi.com"
        link="//www.kiwi.com/"
      >
        <p>
          Dvakrát měsíčně jsme konzultovali marketingové analýzy - pomáhali s
          pochopením souvislostí, které analýzy mají, jak k nim přistoupit a
          jaká doporučení na jejich základě udělat. Postupně jsme spolupráci
          rozšířili o výkonnostní kampaně na sociálních sítích a e-mailing.
        </p>
        <p>
          Kiwi má vlastní marketingový i analytický tým, který zpracovává pro
          marketing analýzy a celkově mu pomáhá v práci. Pro Kiwi tedy fungujeme
          částečně tak, že některé věci zpracováváme sami (například v rámci
          e-mailingu), ale primárně je naše spolupráce konzultačně-mentorská.
        </p>
      </ReferenceImageText>
      <ReferenceImageText
        responsiveTextOrder
        img={OldPlane}
        alt="ilustrativní fotografie kohoutků na vodu v koupelně"
      >
        <h2>Analytické projekty</h2>
        <p>
          <strong>CLV, či zákaznická báze</strong> - Náš první projekt byla
          Customer Lifetime Value (CLV). Cílem bylo dojít k maximální částce,
          kterou se Kiwi.com vyplatí investovat do akvizice nového zákazníka
          (Cost per Acquisition - CPA), tak aby zůstalo stále ziskové a přitom
          zbytečně nebrzdilo potenciál.
        </p>
        <p>
          Vzhledem k tomu, jak rychle v posledních letech Kiwi.com rostlo a jak
          se jim rozrůstaly marketingové kanály, nemohli jsme spolehlivě využít
          běžné prediktivní modely pro spočítání budoucích zisků - historická
          data totiž v tomto případě nejsou dobrým základem pro odhad dalšího
          vývoje.
        </p>
      </ReferenceImageText>

      <ReferenceImageText right img={City} alt="">
        <p>
          Využili jsme tedy místo CLV Customer Base Analysis, které není závislé
          na budoucí hodnotě zákazníků, a maximální CPA jsme tak určili na
          základě průměrné historické hodnoty zákazníka extrapolované o současné
          aktivity.
        </p>
        <p>
          <strong>
            Inkrementalita (vyhodnocení vlivu propagace na rozvíjejícím se trhu)
          </strong>{" "}
          - Dalším větším projektem byla příprava experimentu pro vyhodnocení
          posílení propagace na rozvíjejících se trzích. Cílem bylo vyhodnotit,
          jaký přínos ve skutečnosti tyto aktivity mají a lépe tak do budoucna
          predikovat podobné aktivity.
        </p>
        <p>
          Z dalších analytických projektů, které řešíme stojí určitě za zmínku
          reporting pro marketing spojený s doporučeními na úpravy OKRs
          (celofiremních cílů), designování A/B testů, přístupy k vyhodnocování
          kampaní a způsob atribuování nákladů vzhledem ke zpoždění
          marketingových aktivit.
        </p>
      </ReferenceImageText>

      <LongReferenceImage src={Runway} />

      <Medailon
        personName="Karel Souček"
        position="exVP of Growth, Kiwi.com"
        img={Soucek}
      >
        <p>
          Spolupráce se společností igloonet je výborná. Zadání plní na 100 % a
          zároveň chodí s vlastními nápady.
        </p>
      </Medailon>

      <ReferenceImageText right img={Wing} alt="">
        <h2>Sociální sítě</h2>
        <p>
          <strong>
            Konzultace k výkonnostním kampaním na sociálních sítích
          </strong>{" "}
          - Působíme jako partner, který pomáhá otevřít občas tunelové vidění
          interních týmů. Největší smysl podle nás mají konzultace v přípravné
          fázi, kdy pomáháme specialistům ujasnit si plán propagace, a ve fázi
          vyhodnocování, kdy probíráme, jaké poznatky si vzít pro příští
          kampaně.
        </p>
      </ReferenceImageText>

      <ReferenceImageText
        halfImgSize
        responsiveTextOrder
        img={Emailing}
        alt="ilustrativní fotografie kohoutků na vodu v koupelně"
      >
        <h2>E‑mailing, push notifikace</h2>

        <p>
          <strong>Realizace blast e-mailových kampaní</strong> - Validovali a
          doplnili jsme workflow pro zadávání hromadných e-mailových kampaní z
          dalších oddělení a dál zajišťujeme jejich zpracování. Retenčnímu týmu
          jsme tak uvolnili ruce pro strategičtější práce.
        </p>
        <p>
          <strong>Práce s Exponeou, příprava automatizovaných e-mailů</strong> -
          Kiwi.com využívá pro orchestraci marketingových e-mailů Exponeu. S
          interním týmem řešíme jak nastavování jejich workflow, tak samotné
          kódování e-mailů.
        </p>
        <p>
          <strong>
            Konzultace k e-mailové strategii, technickému pozadí e-mailingu a
            push notifikacím
          </strong>
          - Většinou se interním týmům nevyplácí mít člověka na technickou
          stránku e-mailingu. Ať už je to práce s API, či s Jinjou (Python
          template engine, který Exponea využívá). Kiwi.com jako technologická
          firma má i v tomto technické zázemí velmi dobré, fungujeme tedy jako
          poradní hlas, či řešíme složitější nastavení.
        </p>
        <p>
          <strong>Mentoring interního týmu</strong> - Pravidelně se potkáváme s
          e-mailingovým týmem, snažíme se rozvíjet způsob, jakým nad e-mailingem
          přemýšlí, případně procházíme specifické možnosti nastavení.
        </p>
      </ReferenceImageText>

      <LongReferenceImage src={Mountains} />

      <Medailon
        personName="Lucie Pezlarová"
        position="Head of Analytics, Kiwi.com"
        img={Pezlarova}
      >
        <p>
          S Adamem spolupracujeme již od roku 2018. Pomáhá analytikům i
          marketingovým manažerům Kiwi.com nastavit vhodné postupy pro
          vyhodnocování celého portfolia marketingových aktivit tak, aby
          výsledný reporting dával smysl a hodnoty byly použitelné pro
          rozhodování.
        </p>
        <p>
          Růst Kiwi.com, rozdílné trhy a narůstající spektrum marketingových
          kanálů vyžaduje velmi expertní přístup k vyhodnocení a návrhu
          budoucích aktivit. Adam je v tomto prostředí pro Kiwi.com velkou
          oporou, přináší ověřené znalosti, zdravý rozum, nové poznatky z
          analytických konferencí a vždy dokáže říci, kam jeho expertiza sahá a
          co je třeba prokázat jinak.
        </p>
        <p>
          Nastavovali jsme pod jeho vedením firemní cile pro kampaně, počítali
          Customer Lifetime Value, tvořili reporty a vyhodnocovali inkrementální
          testy. Adam je předním expertem a navíc důvěryhodným a organizovaným
          partnerem pro diskusi; spolupráce s ním si velmi vážím. Věřím, že
          kooperace s Kiwi.com bude úspěšně pokračovat i nadále.
        </p>
      </Medailon>

      <ContactForm contact="adam" />
    </Layout>
  )
}

export default Kiwi
